import elementClosest from 'element-closest'
import 'formdata-polyfill'
import 'classlist.js'
import 'whatwg-fetch'
import 'core-js/stable'
import { embedAll } from '../js/utils/embed'
import renderIcons from './icon'
import svg4everybody from 'svg4everybody'
import { i18n } from '../js/i18n'

/**
 * Autosize textarea
 * @param {HTMLTextAreaElement} el textarea element
 */
function autosize(el) {
    let offset = el.offsetHeight - el.clientHeight

    el.addEventListener('input', (ev) => {
        const textarea = /** @type {HTMLTextAreaElement} */ (ev.target)
        textarea.style.height = 'auto'
        textarea.style.height = (textarea.scrollHeight + offset) + 'px'
    })
}

/**
 * Display an icon from the evil-icons set
 * @param {string} name Icon name from the iconset
 * @returns {string} HTML markup for the selected icon
 */
function evilIcon(name) {
    return `<div data-icon="${name}" data-size="s"></div>`
}

/**
 * Submit form on Ctrl+Enter
 * @param {HTMLElement} formEl Target form element
 * @param {KeyboardEvent} ev Keyboard event
 */
function postformListener(formEl, ev) {
    if (ev.ctrlKey && (ev.keyCode == 10 || ev.keyCode == 13)) {
        let form = formEl.closest('form')
        if (!form.onsubmit || form.submit()) {
            /** @type {HTMLInputElement} */ (form.querySelector('input[type="submit"]')).click()
        }
    }
}

/**
 * Close dialog on Esc
 * @param {KeyboardEvent} ev Keyboard event
 */
function closeDialogListener(ev) {
    ev = ev || /** @type {KeyboardEvent} */ (window.event)
    if (ev.keyCode == 27) {
        closeDialog()
    }
}

/**
 * 
 * @param {Response} response
 */
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText)
    }
    return response
}

/**
 *
 * @param {number} mid message id
 * @param {string} rid reply id
 */
function showCommentForm(mid, rid) {
    let reply = /** @type { HTMLElement } */ (document.getElementById(rid))
    let formTarget = reply.querySelector('div.msg-cont .msg-comment-target')
    if (formTarget) {
        let formHtml = `
            <form>
                <input type="hidden" name="mid" value="${mid}">
                <input type="hidden" name="rid" value="${rid}">
                <div class="msg-comment">
                    <div class="ta-wrapper">
                        <textarea name="body" rows="1" class="reply" placeholder="${i18n('comment.writeComment')}"></textarea>
                        <div class="attach-photo">${evilIcon('ei-camera')}</div>
                    </div>
                    <input type="submit" class="Button" value="OK">
                </div>
            </form>`
        formTarget.insertAdjacentHTML('afterend', formHtml)
        formTarget.remove()
        renderIcons()
        let form = /** @type {HTMLFormElement} */ (reply.querySelector('form'))
        let submitButton = /** @type {HTMLInputElement} */ (form.querySelector('input[type="submit"]'))

        let attachButton = /** @type {HTMLInputElement} */ (form.querySelector('.msg-comment .attach-photo'))
        attachButton.addEventListener('click', e => attachCommentPhoto(/** @type {HTMLDivElement} */(e.target)))

        let textarea = /** @type {HTMLTextAreaElement} */ (form.querySelector('.msg-comment textarea'))
        textarea.addEventListener('keypress', e => postformListener(/** @type {HTMLElement} */(e.target), e))
        autosize(textarea)

        let validateMessage = () => {
            let len = textarea.value.length
            if (len > 4096) { return 'Message is too long' }
            return ''
        }
        form.addEventListener('submit', e => {
            let validationResult = validateMessage()
            if (validationResult) {
                e.preventDefault()
                alert(validationResult)
                return false
            }
            submitButton.disabled = true
            let formData = new FormData(form)
            fetch('/api/comment', {
                method: 'POST',
                body: formData,
                credentials: 'include'
            }).then(response => response.json())
                .then(result => {
                    if (result.newMessage) {
                        window.location.hash = `#${result.newMessage.rid}`
                        window.location.reload()
                    } else {
                        alert(result.text)
                    }
                }).catch(error => {
                    alert(error.message)
                })
            e.preventDefault()
        })
    }
    /** @type {HTMLTextAreaElement} */ (reply.querySelector('.msg-comment textarea')).focus()
}

function attachInput() {
    let inp = document.createElement('input')
    inp.setAttribute('type', 'file')
    inp.setAttribute('name', 'attach')
    inp.setAttribute('accept', 'image/jpeg,image/png')
    inp.style.visibility = 'hidden'
    return inp
}

/**
 * "Attach" button
 * @param {HTMLDivElement} div element attach to
 */
function attachCommentPhoto(div) {
    let input = div.querySelector('input')
    if (input) {
        input.remove()
        div.classList.remove('attach-photo-active')
    } else {
        let newInput = attachInput()
        newInput.addEventListener('change', function() {
            div.classList.add('attach-photo-active')
        })
        newInput.click()
        div.appendChild(newInput)
    }
}

function showPhotoDialog(fname) {
    let width = window.innerWidth
    let height = window.innerHeight
    let minDimension = (width < height) ? width : height
    if (minDimension < 640) {
        return true // no dialog, open the link
    } else if (minDimension < 1280) {
        openDialog(`<a href="//i.juick.com/p/${fname}"><img src="//i.juick.com/photos-1024/${fname}"/></a>`, true)
        return false
    } else {
        openDialog(`<a href="//i.juick.com/p/${fname}"><img src="//i.juick.com/p/${fname}"/></a>`, true)
        return false
    }
}

function openDialog(html, image) {
    var dialogHtml = `
        <div id="dialogt">
            <div id="dialogb"></div>
            <div id="dialogw">
                <div id="dialog_header">
                    <div id="dialogc">${evilIcon('ei-close')}</div>
                </div>
                ${html}
            </div>
        </div>`
    let body = /** @type {HTMLElement} */ (document.querySelector('body'))
    body.classList.add('dialog-opened')
    body.insertAdjacentHTML('afterbegin', dialogHtml)
    if (image) {
        let header = /** @type {HTMLElement} */ (document.querySelector('#dialog_header'))
        header.classList.add('header_image')
    }
    document.addEventListener('keydown', closeDialogListener)
    document.querySelector('#dialogb').addEventListener('click', closeDialog)
    document.querySelector('#dialogc').addEventListener('click', closeDialog)
    renderIcons()
}

function closeDialog() {
    document.querySelector('body').classList.remove('dialog-opened')
    document.querySelector('#dialogb').remove()
    document.querySelector('#dialogt').remove()
}

function checkUsername() {
    var uname = document.querySelector('#username').textContent,
        style = /** @type {HTMLElement} */ (document.querySelector('#username')).style
    fetch('/api/users?uname=' + uname)
        .then(handleErrors)
        .then(function() {
            style.background = '#FFCCCC'
        })
        .catch(function() {
            style.background = '#CCFFCC'
        })
}

/******************************************************************************/

function resultMessage(str) {
    var result = document.createElement('p')
    result.textContent = str
    return result
}

function likeMessage(e, mid) {
    if (confirm(i18n('message.likeThisMessage?'))) {
        fetch('/api/like?mid=' + mid, {
            method: 'POST',
            credentials: 'include'
        })
            .then(handleErrors)
            .then(function(response) {
                if (response.ok) {
                    e.closest('article').appendChild(resultMessage('OK!'))
                }
            })
            .catch(function() {
                e.closest('article').appendChild(resultMessage(i18n('error.error')))
            })
    }
    return false
}

function subscribeMessage(e, mid) {
    fetch('/api/subscribe?mid=' + mid, {
        method: 'POST',
        credentials: 'include'
    })
        .then(handleErrors)
        .then(function(response) {
            if (response.ok) {
                window.location.reload()
            } else {
                alert('Something went wrong :(')
            }
        })
        .catch(error => {
            alert(error.message)
        })
    return false
}

/******************************************************************************/

function setPrivacy(e, mid) {
    fetch('/api/messages/set_privacy?mid=' + mid, {
        credentials: 'include',
        method: 'POST'
    })
        .then(handleErrors)
        .then(function(response) {
            if (response.ok) {
                window.location.reload()
            } else {
                alert('Something went wrong :(')
            }
        })
        .catch(console.error)
    return false
}
function toggleWL(e, name) {
    fetch('/api/users/wl?name=' + name, {
        credentials: 'include',
        method: 'POST'
    })
        .then(handleErrors)
        .then(function(response) {
            if (response.ok) {
                window.location.reload()
            } else {
                alert('Something went wrong :(')
            }
        })
        .catch(console.error)
    return false
}

function fetchUserUri(dataUri) {
    let data = new FormData()
    data.append('uri', dataUri)
    return new Promise((resolve) => {
        fetch('/api/u/', {
            method: 'POST',
            body: data
        }).then(handleErrors)
            .then(response => {
                return response.json()
            })
            .then(json => {
                resolve(json)
            })
            .catch(() => {
                resolve({ uname: dataUri, uri: dataUri })
            })
    })
}

/******************************************************************************/

function ready(fn) {
    if (document.readyState != 'loading') {
        fn()
    } else {
        document.addEventListener('DOMContentLoaded', fn)
    }
}

ready(() => {
    elementClosest(window)
    Array.from(document.querySelectorAll('textarea')).forEach((ta) => {
        autosize(ta)
    })
    svg4everybody()
    renderIcons()
    var insertPMButtons = function(e) {
        e.target.classList.add('narrowpm')
        e.target.parentNode.insertAdjacentHTML('afterend', '<input type="submit" class="Button" value="OK"/>')
        e.target.removeEventListener('click', insertPMButtons)
        e.preventDefault()
    };
    /** @type {HTMLTextAreaElement[]} */ (Array.from(document.querySelectorAll('textarea.replypm'))).forEach(function(e) {
        e.addEventListener('click', insertPMButtons)
        e.addEventListener('keypress', function(e) {
            postformListener(/** @type {HTMLElement} */(e.target), e)
        })
    })

    var content = document.getElementById('content')
    if (content) {
        var pageMID = +content.getAttribute('data-mid')
        if (pageMID > 0) {
            Array.from(document.querySelectorAll('li.msg')).forEach(li => {
                let showReplyFormBtn = li.querySelector('.a-thread-comment')
                if (showReplyFormBtn) {
                    showReplyFormBtn.addEventListener('click', function(e) {
                        showCommentForm(pageMID, li.id)
                        e.preventDefault()
                    })
                }
            })
        }
    }
    /** @type {HTMLFormElement[]} */ (Array.from(document.querySelectorAll('.pmmsg'))).forEach(pmmsg => {
        pmmsg.addEventListener('submit', e => {
            let formData = new FormData(pmmsg)
            fetch('/api/pm', {
                method: 'POST',
                body: formData,
                credentials: 'include'
            }).then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    if (result.to) {
                        window.location.href = new URL('/pm/sent', window.location.href).href
                    } else {
                        alert('Something went wrong :(')
                    }
                })
                .catch(error => {
                    alert(error.message)
                })
            e.preventDefault()
        })
    })

    Array.from(document.querySelectorAll('.l .a-privacy')).forEach(function(e) {
        e.addEventListener('click', function(e) {
            setPrivacy(
                e.target,
                document.getElementById('content').getAttribute('data-mid'))
            e.preventDefault()
        })
    })
    Array.from(document.querySelectorAll('.a-vip')).forEach(function(e) {
        e.addEventListener('click', function(e) {
            toggleWL(
                e.target,
                e.target.closest('[data-name]').getAttribute('data-name'))
            e.preventDefault()
        })
    })
    Array.from(document.querySelectorAll('.ir a[data-fname], .msg-media a[data-fname]')).forEach(function(el) {
        el.addEventListener('click', function(e) {
            let fname = /** @type {HTMLElement} */ (e.target).closest('[data-fname]').getAttribute('data-fname')
            if (!showPhotoDialog(fname)) {
                e.preventDefault()
            }
        })
    })

    var username = document.getElementById('username')
    if (username) {
        username.addEventListener('blur', function() {
            checkUsername()
        })
    }

    Array.from(document.querySelectorAll('.l .a-like')).forEach(function(e) {
        e.addEventListener('click', function(e) {
            likeMessage(
                e.target,
                /** @type {HTMLElement} */(e.target).closest('article').getAttribute('data-mid'))
            e.preventDefault()
        })
    })
    Array.from(document.querySelectorAll('.l .a-sub')).forEach(function(e) {
        e.addEventListener('click', function(e) {
            subscribeMessage(
                e.target,
                document.getElementById('content').getAttribute('data-mid'))
            e.preventDefault()
        })
    })
    var unfoldall = document.getElementById('unfoldall')
    if (unfoldall) {
        unfoldall.addEventListener('click', function(e) {
            /** @type {HTMLElement[]} */ (Array.from(document.querySelectorAll('#replies>li'))).forEach(function(e) {
            e.style.display = 'block'
        });
            /** @type {HTMLElement[]} */ (Array.from(document.querySelectorAll('#replies .msg-comments'))).forEach(function(e) {
            e.style.display = 'none'
        })
            e.preventDefault()
        })
    }
    Array.from(document.querySelectorAll('article')).forEach(function(article) {
        if (Array.prototype.some.call(
            Array.from(article.querySelectorAll('.msg-tags a')),
            function(a) {
                return a.textContent === 'NSFW'
            }
        )) {
            article.classList.add('nsfw')
        }
    })
    Array.from(document.querySelectorAll('[data-uri]')).forEach(el => {
        let dataUri = el.getAttribute('data-uri') || ''
        if (dataUri) {
            setTimeout(() => fetchUserUri(dataUri).then(user => {
                let header = el.closest('.msg-header')
                Array.from(header.querySelectorAll('.a-username')).forEach(a => {
                    a.setAttribute('href', user.url || user.uri)
                    let img = a.querySelector('img')
                    if (img && user.avatar) {
                        img.setAttribute('src', user.avatar)
                        img.setAttribute('alt', user.uname)
                    }
                    let textNode = a.childNodes[0]
                    if (textNode.nodeType === Node.TEXT_NODE && textNode.nodeValue.trim().length > 0) {
                        let uname = document.createTextNode(user.uname)
                        a.replaceChild(uname, a.firstChild)
                    }
                })
            }), 100)
        }
    })
    Array.from(document.querySelectorAll('[data-user-uri]')).forEach(el => {
        let dataUri = el.getAttribute('href') || ''
        if (dataUri) {
            setTimeout(() => fetchUserUri(dataUri).then(user => {
                let textNode = el.childNodes[0]
                if (textNode.nodeType === Node.TEXT_NODE && textNode.nodeValue.trim().length > 0) {
                    let uname = document.createTextNode(`@${user.uname}`)
                    el.replaceChild(uname, el.firstChild)
                    el.setAttribute('href', user.url || user.uri)
                }
            }), 100)
        }
    })
    embedAll()
})
